import TopBar from '../components/Topbar';
import Footer from '../components/Footer';
import appGosal from "../assets/images/gosal.png";
import comingSoon from "../assets/images/coming_soon.png";
import '../assets/styles/Apps.css';

function Apps() {

  const downloadFile = (fileUrl, fileName) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;
    link.click();
  };

  return (
    <>
      <div className="topbar-container">
        <TopBar />
      </div>
  
      <div className="cards-container">
        <div className="card-wrapper">
          <h2 className="card-title">Gosal</h2>
          <div className="card">
            <img
              src={appGosal}
              alt="Card 1"
              className="card-image"
            />
          </div>
          <p className="card-description">An earthquake reporting app</p>
          <button 
            className="card-button" 
            onClick={() => downloadFile("https://atlasmana.com/files/gosal.apk", "gosal.apk")}
          >
            <b>DOWNLOAD APK</b>
          </button>
        </div>
        <div className="card-wrapper">
          <h2 className="card-title">Ravand</h2>
          <div className="card">
            <img
              src={comingSoon}
              alt="Card 2"
              className="card-image"
            />
          </div>
          <p className="card-description">A daily personal assistant</p>
          <button 
            className="card-button" 
            disabled          >
            <b>DOWNLOAD APK</b>
          </button>
        </div>
   
      </div>
      <Footer />
    </>
  );
}

export default Apps;
